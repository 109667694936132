<template>
  <el-header>
    <div class="top">
      <div v-if="navInfo" class="top-logo">
        <a href="/"><el-image v-if="info" class="img" :src="info.websiteLogo" alt="logo" /></a>
      </div>
      <div class="nav">
        <div class="nav-title">
          <div v-for="(item, index) in navInfo" :key="index" class="title-name">
            <nuxt-link v-if="!isExternalUrl(item.navUrl)" :to="{ path: item.navUrl }" :class="{ active: activeUrl === item.navUrl }">
              {{ item.navTitle }}
            </nuxt-link>
            <a v-else :href="item.navUrl" :target="item.target">{{ item.navTitle }}</a>
          </div>
        </div>
        <div v-if="pathUrl != '/search'" class="nav-search">
          <el-input v-model="search" autofocus placeholder="请输入搜索内容" @keyup.enter="handleSearch">
            <template #append>
              <span class="cursor" @click="handleSearch">搜索</span>
            </template>
          </el-input>
        </div>
      </div>
      <div v-if="loginStatus" class="top-user">
        <nuxt-link :to="{ name: 'account-course' }">
          <el-button plain>我的课程</el-button>
        </nuxt-link>
        <common-user />
      </div>
      <div v-if="!loginStatus" class="top-register">
        <nuxt-link :to="{ name: 'login' }">登录</nuxt-link> 
        <!-- <el-divider direction="vertical">|</el-divider> <nuxt-link :to="{ name: 'register' }">注册</nuxt-link> -->
      </div>
    </div>
  </el-header>
</template>
<script setup>
  import { indexApi } from '~/api/index.js'
  import { getStorage, setStorage } from '~/utils/storage.js'
  const pathUrl = useRoute().path

  // 网站信息
  const info = ref({})
  // 导航信息
  const navInfo = ref([])
  //
  const loginStatus = ref(false)

  // 搜索
  const search = ref('')
  const activeUrl = ref(useRoute().path)

  onMounted(() => {
    // 站点信息
    info.value = getStorage('WebsiteInfo')
    if (!info.value) {
      indexApi.websiteInfo().then((res) => {
        setStorage('WebsiteInfo', res, 1)
        info.value = res
      })
    }

    // 导航信息
    navInfo.value = getStorage('WebsiteNav')
    if (!navInfo.value) {
      indexApi.websiteNav().then((res) => {
        setStorage('WebsiteNav', res, 1)
        navInfo.value = res
      })
    }
    const token = getToken()
    if (token) {
      loginStatus.value = true
    }
  })

  // 搜索
  function handleSearch() {
    if (!search.value) {
      return
    }
    useRouter().push({ name: 'search', query: { kw: search.value } })
  }
</script>
<style lang="scss" scoped>
  .el-header {
    background: #fff;
    height: $header-height;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #eee;
  }
  .top {
    width: 1300px;
    margin: 0 auto;
    height: calc(($header-height) / 2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .top-logo {
      display: flex;
      align-items: center;
      .img {
        height: 35px;
        width: auto;
      }
    }
    .nav {
      height: calc(($header-height - 20px) / 2);
      display: flex;
      align-items: center;
      justify-content: start;
      flex: 1;
      margin: 0 35px;
      .nav-title {
        display: flex;
        font-size: 17px;
        .title-name {
          margin-right: 35px;
          a:hover {
            color: $second-color;
          }
        }
      }
      .nav-search {
        margin-left: 35px;
        .el-input {
          height: 40px;
          width: 300px;
          line-height: 40px;
        }
      }
    }
    .top-user {
      width: 200px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .top-register {
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 10px 15px;
      font-size: 14px;
      a:hover {
        color: $second-color;
      }
    }
  }
  .active {
    color: $main-color;
    font-size: 18px;
  }
  :deep(.el-input-group__append) {
    background-color: $main-color;
    color: #ffffff;
    width: 30px;
    cursor: pointer;
  }
</style>
